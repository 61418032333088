//Imports
import './Styles/RodapeNovaya.style.css';

const RodapeNovaya = () => {
    return (
        <div id="rodape-novaya">
            <a tabIndex={83} href="https://www.instagram.com/novaya.on/" target='_blanck'>Site desenvolvido pela agencia Novaya - 2023</a>
        </div>
    )
}

export default RodapeNovaya;