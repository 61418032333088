import './Styles/CoursesAndSolutions.style.css'

const CoursesAndSolutions = () => {
    return (
        <div id='CoursesAndSolutions'>
            <h2 tabIndex={16}>Conheça nossos cursos e Soluções</h2>
            <p tabIndex={17}>Plataforma completa e criativa, com conteúdo dinâmico, EAD, presencial ou híbrido</p>
        </div>
    );
};

export default CoursesAndSolutions;